<template>
  <v-container fluid tag="section" class="pt-0">
    <v-card elevation="0" class="px-5 py-3 br-16">
      <v-tabs v-model="tab" color="primary">
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-customs">My Categories</v-tab>
        <v-tab href="#tab-admin">Categories Settings</v-tab>
        <v-tab href="#tab-mains">Landing Categories</v-tab>

        <v-tab-item value="tab-customs">
          <CustomCategories />
        </v-tab-item>
        <v-tab-item value="tab-admin">
          <CategoriesAdmin />
        </v-tab-item>
        <v-tab-item value="tab-mains" class="pt-4">
          <MainCategories />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import CategoriesAdmin from "./CategoriesAdmin";
import CustomCategories from "./CustomCategories";
import MainCategories from "./MainCategories";

export default {
  name: "Categories",
  components: {
    CustomCategories,
    CategoriesAdmin,
    MainCategories,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
