var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"unset","persistent":"","content-class":"br-16"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.has_rules)?_c('div',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"accent"},domProps:{"textContent":_vm._s('$clock-done')}})],1),_c('v-chip',{attrs:{"pill":"","outlined":"","small":"","color":"accent"}},[_vm._v("Activated")])],1):_c('div',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('$clock-add')}})],1)],1)]}}]),model:{value:(_vm.show_dialog),callback:function ($$v) {_vm.show_dialog=$$v},expression:"show_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary font-weight-bold pb-4 text-center white--text"},[_vm._v("Add New Category Automation Rules")]),_c('v-card-text',[_c('div',{staticClass:"br-8 px-6 pt-4 mb-4 d-flex justify-space-around light-grey"},[_c('p',[_c('span',{staticClass:"text-h4"},[_vm._v("Category")]),_vm._v(": "+_vm._s(_vm.prettyCategory))]),_c('p',[_c('span',{staticClass:"text-h4"},[_vm._v("Service")]),_vm._v(": "+_vm._s(_vm.prettyService))])]),_c('p',{staticClass:"text-h4 mb-4"},[_vm._v(" Your category "+_vm._s(_vm.prettyCategory)+" will be automatically enabled on: ")]),_c('v-form',{model:{value:(_vm.are_rules_valid),callback:function ($$v) {_vm.are_rules_valid=$$v},expression:"are_rules_valid"}},_vm._l((Object.entries(
            _vm.rules_groupped_by_day
          )),function(ref,idx){
          var rule = ref[0];
          var days_nos = ref[1];
return _c('div',{key:idx},[_c('div',{staticClass:"py-4 px-4 br-8 mb-4 dark-grey--border"},[_c('v-select',{staticClass:"required pt-0 mb-4 max-width-400",attrs:{"dense":"","placeholder":"Select days","multiple":"","required":"","hide-details":"","clearable":"","items":_vm.available_rules_days(days_nos),"value":days_nos},on:{"input":function (days) { return _vm.handle_select_day_click(rule, days); },"click:clear":function($event){$event.preventDefault();$event.stopPropagation();return _vm.delete_whole_rule(days_nos)}}}),_vm._l((_vm.format_rule_to_array_of_times(
                rule
              )),function(formatted_rule,idx_j){return _c('RuleTimeSelect',{key:(rule + "_" + idx_j),attrs:{"range":formatted_rule,"isFirst":idx_j === 0,"select-class":"time-select max-width-120 mr-4 pt-0","previous-rule-end":_vm.get_previous_rule_end(rule, idx_j)},on:{"update:rule":function (new_rule) { return _vm.update_rule(days_nos, idx_j, new_rule); }},scopedSlots:_vm._u([(
                  idx_j + 1 === _vm.format_rule_to_array_of_times(rule).length
                )?{key:"add",fn:function(){return [_c('div',{staticClass:"d-flex justify-end pt-4"},[_c('v-btn',{staticClass:"mr-0",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.add_days_rule(days_nos)}}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('$clock-add')}})],1)],1)]},proxy:true}:null,(idx_j !== 0)?{key:"delete",fn:function(){return [_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","small":"","color":"accent"},on:{"click":function($event){return _vm.delete_rule(days_nos, idx_j)}}},[_c('v-icon',{attrs:{"color":"accent"},domProps:{"textContent":_vm._s('$delete')}})],1)]},proxy:true}:null],null,true)})}),(rule.length === 0)?_c('div',{staticClass:"d-flex justify-end pt-4"},[_c('v-btn',{staticClass:"mr-0",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.add_first_empty_rule(days_nos)}}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('$clock-add')}})],1)],1):_vm._e()],2)])}),0),(_vm.available_select_day_items.length)?_c('v-btn',{attrs:{"color":"primary","small":"","outlined":"","rounded":""},on:{"click":_vm.create_empty_rule}},[_c('span',{staticClass:"no-text-transform black--text text-h5"},[_vm._v("+ Add more")])]):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end pr-6"},[(Object.keys(_vm.existing_rules).length !== 0)?_c('v-btn',{attrs:{"color":"accent","text":"","rounded":""},on:{"click":_vm.delete_automation}},[_vm._v("Delete Automation")]):_vm._e(),_c('v-btn',{attrs:{"color":"black","text":"","rounded":""},on:{"click":function($event){_vm.show_dialog = false}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"primary-alt mr-0",attrs:{"disabled":_vm.available_select_day_items.length === 7 ||
          !_vm.are_rules_valid ||
          _vm.loading,"elevation":"0","rounded":""},on:{"click":_vm.save_automation}},[(!_vm.loading)?_c('span',{staticClass:"text-uppercase"},[_vm._v("Save")]):_c('v-progress-circular',{attrs:{"size":"10","indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }