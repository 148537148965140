<template>
  <v-dialog
    v-model="show_dialog"
    width="unset"
    persistent
    content-class="br-16"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-if="has_rules">
        <v-btn color="accent" icon v-bind="attrs" v-on="on">
          <v-icon color="accent" v-text="'$clock-done'" />
        </v-btn>
        <v-chip pill outlined small color="accent">Activated</v-chip>
      </div>
      <div v-else>
        <v-btn color="primary" icon v-bind="attrs" v-on="on">
          <v-icon color="primary" v-text="'$clock-add'" />
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title
        class="primary font-weight-bold pb-4 text-center white--text"
        >Add New Category Automation Rules</v-card-title
      >
      <v-card-text
        ><div
          class="br-8 px-6 pt-4 mb-4 d-flex justify-space-around light-grey"
        >
          <p><span class="text-h4">Category</span>: {{ prettyCategory }}</p>
          <p><span class="text-h4">Service</span>: {{ prettyService }}</p>
        </div>
        <p class="text-h4 mb-4">
          Your category {{ prettyCategory }} will be automatically enabled on:
        </p>
        <v-form v-model="are_rules_valid">
          <div
            v-for="([rule, days_nos], idx) in Object.entries(
              rules_groupped_by_day
            )"
            :key="idx"
          >
            <div class="py-4 px-4 br-8 mb-4 dark-grey--border">
              <v-select
                dense
                class="required pt-0 mb-4 max-width-400"
                placeholder="Select days"
                multiple
                required
                hide-details
                clearable
                :items="available_rules_days(days_nos)"
                :value="days_nos"
                @input="(days) => handle_select_day_click(rule, days)"
                @click:clear.prevent.stop="delete_whole_rule(days_nos)"
              ></v-select>
              <RuleTimeSelect
                v-for="(formatted_rule, idx_j) in format_rule_to_array_of_times(
                  rule
                )"
                :key="`${rule}_${idx_j}`"
                :range="formatted_rule"
                :isFirst="idx_j === 0"
                select-class="time-select max-width-120 mr-4 pt-0"
                :previous-rule-end="get_previous_rule_end(rule, idx_j)"
                @update:rule="
                  (new_rule) => update_rule(days_nos, idx_j, new_rule)
                "
              >
                <template
                  #add
                  v-if="
                    idx_j + 1 === format_rule_to_array_of_times(rule).length
                  "
                  ><div class="d-flex justify-end pt-4">
                    <v-btn
                      icon
                      class="mr-0"
                      small
                      color="primary"
                      @click="add_days_rule(days_nos)"
                      ><v-icon color="primary" v-text="'$clock-add'"
                    /></v-btn></div
                ></template>
                <template #delete v-if="idx_j !== 0"
                  ><v-btn
                    icon
                    small
                    class="ml-4"
                    color="accent"
                    @click="delete_rule(days_nos, idx_j)"
                    ><v-icon color="accent" v-text="'$delete'" /></v-btn
                ></template>
              </RuleTimeSelect>
              <div class="d-flex justify-end pt-4" v-if="rule.length === 0">
                <v-btn
                  icon
                  class="mr-0"
                  small
                  color="primary"
                  @click="add_first_empty_rule(days_nos)"
                  ><v-icon color="primary" v-text="'$clock-add'"
                /></v-btn>
              </div>
            </div>
          </div>
        </v-form>
        <v-btn
          v-if="available_select_day_items.length"
          color="primary"
          small
          outlined
          rounded
          @click="create_empty_rule"
          ><span class="no-text-transform black--text text-h5"
            >+ Add more</span
          ></v-btn
        >
      </v-card-text>
      <v-card-actions class="justify-end pr-6">
        <v-btn
          v-if="Object.keys(existing_rules).length !== 0"
          color="accent"
          text
          rounded
          @click="delete_automation"
          >Delete Automation</v-btn
        >
        <v-btn color="black" text rounded @click="show_dialog = false"
          >Close</v-btn
        >
        <v-btn
          :disabled="
            available_select_day_items.length === 7 ||
            !are_rules_valid ||
            loading
          "
          elevation="0"
          rounded
          @click="save_automation"
          class="primary-alt mr-0"
        >
          <span v-if="!loading" class="text-uppercase">Save</span>
          <v-progress-circular
            v-else
            size="10"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/db";
import { mapState } from "vuex";
import { days } from "@/shared/const";
import { field_req } from "@/utils/form_val_rules";

import RuleTimeSelect from "./RuleTimeSelect";

const GENERAL_RULE_DAY_NO = -1;

export default {
  name: "CategoriesRulesDialog",
  props: {
    serviceId: { type: String, default: null },
    categoryId: { type: String, default: null },
    prettyService: { type: String, default: null },
    prettyCategory: { type: String, default: null },
  },
  components: {
    RuleTimeSelect,
  },
  data() {
    return {
      days,
      GENERAL_RULE_DAY_NO,
      field_req,
      selected_days: {},
      show_dialog: false,
      are_rules_valid: false,
      loading: false,
      rules_copy: {},
    };
  },
  watch: {
    show_dialog: function (v) {
      if (v === true) {
        this.rules_copy = { ...this.existing_rules };
      } else {
        this.rules_copy = {};
      }
    },
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "shop_categories_config"]),
    existing_rules() {
      const existing_config =
        this.shop_categories_config?.[this.categoryId] || {};
      const existing_rules = existing_config?.rules?.[this.serviceId] || {};

      return existing_rules;
    },
    has_rules() {
      return Object.keys(this.existing_rules).length !== 0;
    },
    rules_groupped_by_day() {
      const groups = {};
      for (const [day_no, rule] of Object.entries(this.rules_copy)) {
        if (groups?.[rule] ?? false) {
          groups[rule].push(Number(day_no));
        } else {
          groups[rule] = [Number(day_no)];
        }
      }

      return groups;
    },
    select_day_items() {
      const items = Object.entries(days).map(([value, text]) => ({
        value: Number(value),
        text,
      }));

      return items;
    },
    available_select_day_items() {
      const days_taken = Object.keys(this.rules_copy).map((d) => Number(d));
      return this.select_day_items.filter(
        (day) => !days_taken.includes(day.value)
      );
    },
  },
  methods: {
    rule_selected_days(days_no) {
      return this.select_day_items.filter((day) => days_no.includes(day.value));
    },
    available_rules_days(days_nos) {
      return [
        ...this.available_select_day_items,
        ...this.rule_selected_days(days_nos),
      ].sort((a, b) => {
        return a.value - b.value;
      });
    },
    handle_select_day_click(rule, days) {
      let rules = { ...this.rules_copy };

      const days_by_rule = this.rules_groupped_by_day;

      let previous_days = days_by_rule?.[rule] ?? [];
      previous_days = previous_days.map((d) => Number(d));
      days = days.map((d) => Number(d));

      let is_adding = previous_days.length < days.length;

      let selected_day;
      if (is_adding) {
        selected_day = days.find((day) => !previous_days.includes(day));
        rules[selected_day] = rule;
      } else {
        selected_day = previous_days.find((day) => !days.includes(day));
        delete rules[selected_day];
      }

      this.rules_copy = rules;
    },
    create_empty_rule() {
      const first_available_day = this.available_select_day_items[0];
      const rules = { ...this.rules_copy };
      rules[first_available_day.value] = "-";
      this.rules_copy = rules;
    },

    format_rule_to_array_of_times(rule) {
      if (!rule) return null;
      const ranges = rule.split("~");
      return ranges.map((range) => range.split("-"));
    },
    get_previous_rule_end(rule, idx) {
      const rules_array = this.format_rule_to_array_of_times(rule);
      if (idx === 0) return null;
      return rules_array[idx - 1][1];
    },
    update_rule(day_numbers, idx, new_rule) {
      const rules = { ...this.rules_copy };
      for (const day_no of day_numbers) {
        const rule = rules[day_no];
        const ranges = rule.split("~");
        ranges.splice(idx, 1, new_rule);
        rules[day_no] = ranges.join("~");
      }
      this.rules_copy = rules;
    },
    delete_whole_rule(day_numbers) {
      const rules = { ...this.rules_copy };
      for (const day_no of day_numbers) {
        delete rules[day_no];
      }
      this.rules_copy = rules;
    },
    delete_rule(day_numbers, idx) {
      const rules = { ...this.rules_copy };
      for (const day_no of day_numbers) {
        const rule = rules[day_no];
        const ranges = rule.split("~");
        ranges.splice(idx, 1);
        rules[day_no] = ranges.join("~");
      }
      this.rules_copy = rules;
    },
    async save_automation() {
      const config = { ...this.shop_categories_config };
      let existing_config = config[this.categoryId] ?? {};
      let existing_rules = existing_config?.rules ?? {};

      existing_rules[this.serviceId] = this.rules_copy;
      existing_config.rules = existing_rules;

      config[this.categoryId] = existing_config;
      await this.update_shop_categories_config(config);
    },
    async delete_automation() {
      const config = { ...this.shop_categories_config };

      delete config[this.categoryId].rules[this.serviceId];

      await this.update_shop_categories_config(config);
    },
    async update_shop_categories_config(new_config) {
      this.loading = true;

      const config_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("categories")
        .doc("config");

      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(config_ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(config_ref, new_config);
        });
        this.show_dialog = false;
      } catch (err) {
        console.error("Transaction failed", err);
      } finally {
        this.$forceUpdate();
        this.loading = false;
      }
    },

    add_first_empty_rule(day_numbers) {
      const rules = { ...this.rules_copy };
      for (const day_no of day_numbers) {
        rules[day_no] = "-";
      }
      this.rules_copy = rules;
    },
    add_days_rule(day_numbers) {
      const rules = { ...this.rules_copy };
      for (const day_no of day_numbers) {
        const rule = rules[day_no];
        const ranges = rule.split("~");
        ranges.push("-");
        rules[day_no] = ranges.join("~");
      }
      this.rules_copy = rules;
    },
  },
};
</script>

<style lang="scss" scoped>
.max-width-400 {
  max-width: 400px;
}
.dark-grey--border {
  border: 1px solid var(--v-dark-grey-base);
}
</style>
