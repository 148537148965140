<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" v-if="!can_accept_orders">
        <v-alert type="info" outlined color="primary">
          <span class="black--text">{{ alert_message }}</span>
        </v-alert>
      </v-col>
      <v-col v-else cols="12">
        <v-row no-gutters>
          <v-col cols="12" xl="6" class="pt-4">
            <v-alert type="info" outlined color="primary">
              <span class="black--text"
                >We are in process of an update. If you are not part of
                Foodiependent, please do NOT use the category automation feature
                until this message disappears as it may not work yet.</span
              >
            </v-alert>
          </v-col>
          <v-col cols="12">
            <p class="mt-4 mb-0">
              Control which services you would like your categories to display
              in. You can also automate your categories to display at a chosen
              time by clicking on the clock icon.
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="name_filter"
              label=""
              single-line
              placeholder="Search categories"
              clearable
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Category Name</th>
                <th>Active Products</th>
                <th v-if="has_delivery_service">Enable for Delivery</th>
                <th v-if="has_collection_service">Enable for Collection</th>
                <th v-if="has_in_house_service">Enable for In House</th>
                <th v-if="has_takeaway_service">Enable for Takeaway</th>
                <th v-if="has_hospitality_delivery_service">
                  Enable for Delivery
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(val, cat_id) in filtered_product_categories"
                :key="cat_id"
              >
                <td>{{ all_product_categories[cat_id] }}</td>
                <td>{{ val }}</td>
                <td v-if="has_delivery_service">
                  <div class="d-flex align-center">
                    <v-checkbox
                      color="primary"
                      @change="
                        (value) =>
                          set_category_config_value(cat_id, value, {
                            delivery: true,
                          })
                      "
                      :input-value="
                        is_category_enabled(cat_id, { delivery: true })
                      "
                      class="d-inline-block"
                    ></v-checkbox>
                    <CategoriesRulesDialog
                      :pretty-category="all_product_categories[cat_id]"
                      pretty-service="Delivery"
                      service-id="delivery"
                      :category-id="cat_id"
                    />
                  </div>
                </td>
                <td v-if="has_collection_service">
                  <div class="d-flex align-center">
                    <v-checkbox
                      color="primary"
                      @change="
                        (value) =>
                          set_category_config_value(cat_id, value, {
                            collection: true,
                          })
                      "
                      :input-value="
                        is_category_enabled(cat_id, { collection: true })
                      "
                      class="d-inline-block"
                    ></v-checkbox>
                    <CategoriesRulesDialog
                      :pretty-category="all_product_categories[cat_id]"
                      pretty-service="Collection"
                      service-id="collection"
                      :category-id="cat_id"
                    />
                  </div>
                </td>
                <td v-if="has_in_house_service">
                  <div class="d-flex align-center">
                    <v-checkbox
                      color="primary"
                      @change="
                        (value) =>
                          set_category_config_value(cat_id, value, {
                            in_house: true,
                          })
                      "
                      :input-value="
                        is_category_enabled(cat_id, { in_house: true })
                      "
                      class="mr-2 d-inline-block"
                    ></v-checkbox>
                    <CategoriesRulesDialog
                      :pretty-category="all_product_categories[cat_id]"
                      pretty-service="In House"
                      service-id="in_house"
                      :category-id="cat_id"
                    />
                  </div>
                </td>
                <td v-if="has_takeaway_service">
                  <div class="d-flex align-center">
                    <v-checkbox
                      color="primary"
                      @change="
                        (value) =>
                          set_category_config_value(cat_id, value, {
                            takeaway: true,
                          })
                      "
                      :input-value="
                        is_category_enabled(cat_id, { takeaway: true })
                      "
                      class="d-inline-block"
                    ></v-checkbox>
                    <CategoriesRulesDialog
                      :pretty-category="all_product_categories[cat_id]"
                      pretty-service="Takeaway"
                      service-id="takeaway"
                      :category-id="cat_id"
                    />
                  </div>
                </td>
                <td v-if="has_hospitality_delivery_service">
                  <div class="d-flex align-center">
                    <v-checkbox
                      color="primary"
                      @change="
                        (value) =>
                          set_category_config_value(cat_id, value, {
                            hospitality_delivery: true,
                          })
                      "
                      :input-value="
                        is_category_enabled(cat_id, {
                          hospitality_delivery: true,
                        })
                      "
                      class="d-inline-block"
                    ></v-checkbox>
                    <CategoriesRulesDialog
                      :pretty-category="all_product_categories[cat_id]"
                      pretty-service="Delivery"
                      service-id="hospitality_delivery"
                      :category-id="cat_id"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="show_snackbar"
      :timeout="1500"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
      ><b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="show_snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template></v-snackbar
    >
  </div>
</template>

<script>
import { db } from "@/db";
import { mapState, mapGetters } from "vuex";
import { cloneDeep, pickBy } from "lodash";

import CategoriesRulesDialog from "./components/CategoriesRulesDialog";

export default {
  name: "CategoriesAdmin",
  data() {
    return {
      name_filter: "",
      show_snackbar: false,
    };
  },
  components: {
    CategoriesRulesDialog,
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "shop_categories_config"]),
    ...mapGetters("AdminStore", [
      "has_collection_service",
      "has_delivery_service",
      "has_in_house_service",
      "has_takeaway_service",
      "has_hospitality_delivery_service",
      "can_accept_orders",
      "all_product_categories",
      "is_directory_shop",
      "active_categories",
    ]),
    ...mapGetters("BrandStore", ["brand_contact"]),
    is_category_enabled: function () {
      return (
        category_id,
        { delivery, collection, in_house, takeaway, hospitality_delivery }
      ) => {
        if (delivery) {
          return this.shop_categories_config[category_id]?.delivery ?? true;
        }
        if (collection) {
          return this.shop_categories_config[category_id]?.collection ?? true;
        }
        if (in_house) {
          return this.shop_categories_config[category_id]?.in_house ?? true;
        }
        if (takeaway) {
          return this.shop_categories_config[category_id]?.takeaway ?? true;
        }
        if (hospitality_delivery) {
          return (
            this.shop_categories_config[category_id]?.hospitality_delivery ??
            true
          );
        }
        return true;
      };
    },
    filtered_product_categories: function () {
      if (this.name_filter) {
        return pickBy(this.active_categories, (_, key) =>
          key.includes(this.name_filter)
        );
      } else {
        return Object.fromEntries(
          Object.entries(this.active_categories).sort()
        );
      }
    },
    alert_message() {
      const directory_shop_msg = `
        To manage categories your shop needs to be enabled to trade.
        If you'd like to extend your shop functionality please contact
        ${this.brand_contact || "support@wearelocals.co.uk"}`;
      const no_services_msg = `
        To manage your categories first you have to accept at least one service method`;
      return this.is_directory_shop ? directory_shop_msg : no_services_msg;
    },
  },
  methods: {
    async set_category_config_value(
      category_id,
      value,
      { delivery, collection, in_house, takeaway, hospitality_delivery }
    ) {
      const config = cloneDeep(this.shop_categories_config);
      let existing_config = config[category_id] ?? {};

      if (delivery) {
        existing_config.delivery = value;
      }
      if (collection) {
        existing_config.collection = value;
      }
      if (in_house) {
        existing_config.in_house = value;
      }
      if (takeaway) {
        existing_config.takeaway = value;
      }
      if (hospitality_delivery) {
        existing_config.hospitality_delivery = value;
      }

      config[category_id] = existing_config;
      const config_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("categories")
        .doc("config");

      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(config_ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(config_ref, config);
        });
        this.show_snackbar = true;
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
  },
};
</script>
