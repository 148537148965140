<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p>
          Choose your desired product category for your customers to land on for
          each of your services!<br />
          Please note: If the products from your landing category have sold out
          or removed, the system will automatically choose another available
          category.
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" v-for="service in shop_services" :key="service.id">
            <h2 class="mb-4">{{ service.pretty_name }}</h2>
            <v-autocomplete
              name="new-password"
              autocomplete="new-password"
              :prepend-icon="service.icon"
              label="Landing Category"
              :value="actual_main_category_for_service(service.id)"
              :items="active_categories_for_service_id__options(service.id)"
              item-text="text"
              item-value="value"
              no-data-text="No Categories Found"
              @change="(category) => update_main_category(service.id, category)"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      :timeout="1500"
      v-model="show_snackbar"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
      ><b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="show_snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template></v-snackbar
    >
  </div>
</template>

<script>
import { db } from "@/db";
import { mapGetters, mapState } from "vuex";

export default {
  name: "MainCategories",
  data() {
    return {
      show_snackbar: false,
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop_id",
      "shop_products_categories",
      "main_categories",
    ]),
    ...mapGetters("AdminStore", [
      "shop_services",
      "all_product_categories",
      "active_categories_for_service_id__options",
    ]),
    actual_main_category_for_service: function () {
      return (service_id) => {
        let cat = this.main_categories[service_id] || null;
        return cat;
      };
    },
  },
  methods: {
    async update_main_category(service, category_id) {
      const mains_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("categories")
        .doc("mains");

      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(mains_ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(mains_ref, {
            [service]: category_id,
          });
        });
        this.show_snackbar = true;
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
  },
};
</script>
