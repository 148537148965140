<template>
  <div>
    <v-row align="center">
      <v-col cols="4">
        <v-text-field
          label="New category name"
          v-model="new_category_name"
          clearable
        />
      </v-col>
      <v-col cols="3"
        ><v-btn
          color="primary"
          rounded
          :disabled="!new_category_name || !can_add_new_category"
          @click="add_new_category()"
          >Add new category</v-btn
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12"
        ><h1>
          {{
            has_categories
              ? "All Categories"
              : "Sorry no categories, try to add some."
          }}
        </h1></v-col
      >
      <v-col
        cols="6"
        col="2"
        class="category-col py-0"
        v-for="(name, cat_id) in all_categories"
        :key="cat_id"
      >
        <p
          :class="{
            'primary lighten-1':
              new_category_name &&
              cat_id.includes(format_string_to_uid(new_category_name)),
          }"
          class="pa-1 mb-0 rounded d-inline-block"
        >
          {{ name }} ({{ shop_products_categories[cat_id] || "0" }} product{{
            shop_products_categories[cat_id] > 1 ? "s" : ""
          }})
        </p>
        <v-btn
          @click="delete_category(cat_id)"
          class="delete-btn"
          v-if="
            !shop_products_categories[cat_id] ||
            shop_products_categories[cat_id] == 0
          "
          icon
        >
          <v-icon v-text="'$delete'" color="accent" small />
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="show_snackbar"
      :timeout="1500"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
      ><b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="show_snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template></v-snackbar
    >
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db } from "@/db";
import { format_string_to_uid } from "@/utils/firestore";

export default {
  name: "CustomCategories",
  data() {
    return {
      format_string_to_uid,
      show_snackbar: false,
      new_category_name: null,
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop_id",
      "custom_categories",
      "shop_products_categories",
    ]),
    ...mapGetters("AdminStore", ["all_product_categories"]),
    can_add_new_category() {
      const name = this.new_category_name;
      if (!name || name === "") return false;

      const cat_id = format_string_to_uid(name);
      if (this.all_product_categories[cat_id]) return false;

      return true;
    },
    all_categories() {
      return Object.fromEntries(
        Object.entries(this.all_product_categories).sort()
      );
    },
    has_categories() {
      return Object.keys(this.all_categories).length > 0;
    },
  },
  methods: {
    async add_new_category() {
      const categories = this.custom_categories;
      const cat_id = format_string_to_uid(this.new_category_name);
      categories[cat_id] = this.new_category_name;

      const cat_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("categories")
        .doc("custom_categories");

      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(cat_ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(cat_ref, categories);
        });
        this.show_snackbar = true;
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    async delete_category(category_id) {
      const products_stats = this.shop_products_categories;

      if (products_stats[category_id] && products_stats[category_id] > 0) {
        return;
      }

      const categories = this.custom_categories;
      delete categories[category_id];

      const cat_ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("categories")
        .doc("custom_categories");

      try {
        await cat_ref.set(categories);
        this.show_snackbar = true;
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded {
  border-radius: 8px;
}
::v-deep .category-col {
  .delete-btn {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      opacity: 0;
    }
  }
  &:hover {
    .delete-btn {
      opacity: 1;
    }
  }
}
</style>
