<template>
  <div class="mb-2">
    <div class="d-flex justify-start" :class="{ is_deleting: deleteMode }">
      <p class="text-h5 mr-4 mb-0 align-self-center">From</p>
      <TimeSelectBox
        single-line
        dense
        :rules="[field_req]"
        :disabled="!isFirst && !previousRuleEnd"
        :from="previousRuleEnd"
        :time="_from"
        :select-class="selectClass"
        :prepend-icon="null"
        required
        @update="(v) => (_from = v)"
      />
      <p class="text-h5 mr-4 mb-0 align-self-center">to</p>
      <TimeSelectBox
        single-line
        dense
        :rules="[field_req]"
        :disabled="!_from"
        :from="_from"
        :time="_to"
        :prepend-icon="null"
        :select-class="selectClass"
        required
        @update="(v) => (_to = v)"
      />
      <slot name="delete"></slot>
    </div>
    <slot name="add"></slot>
  </div>
</template>

<script>
import TimeSelectBox from "@/views/dashboard/components/shop_settings/TimeSelectBox";
import { field_req } from "@/utils/form_val_rules";

export default {
  name: "RuleTimeSelect",
  props: {
    range: { type: Array, default: () => [] },
    previousRuleEnd: { type: String, default: null },
    selectClass: { type: String, default: "" },
    deleteMode: { type: Boolean, default: false },
    isFirst: { type: Boolean, default: false },
  },
  components: {
    TimeSelectBox,
  },
  data() {
    return {
      field_req,
    };
  },
  computed: {
    from() {
      const [from] = this.range;
      return from;
    },
    to() {
      const [, to] = this.range;
      return to;
    },
    _from: {
      get() {
        return this.from;
      },
      set(from) {
        this.update_rule(from, this.to);
      },
    },
    _to: {
      get() {
        return this.to;
      },
      set(to) {
        this.update_rule(this.from, to);
      },
    },
  },
  methods: {
    update_rule(from, to) {
      const rule = `${from}-${to}`;
      this.$emit("update:rule", rule);
    },
  },
};
</script>
