<template>
  <v-select
    :required="required"
    :disabled="disabled"
    :single-line="singleLine"
    :dense="singleLine"
    :class="{
      required: required,
      [selectClass]: true,
    }"
    :prepend-icon="prependIcon"
    :items="time_range"
    hide-details
    v-model="_time"
    :label="label"
    :rules="rules"
    :outlined="outlined"
  ></v-select>
</template>

<script>
import {
  get_HHmm_time_as_seconds,
  get_seconds_as_HHmm,
} from "@/utils/date_utils";
import range from "lodash/range";

export default {
  name: "TimeSelectBox",
  props: {
    time: { type: String },
    rules: { type: Array, default: () => [] },
    from: { type: String, default: null },
    to: { type: String, default: null },
    label: { type: String, default: "Select Time" },
    interval: { type: Number, default: 900 }, // 15 minutes
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    selectClass: { type: String, default: "" },
    dense: { type: String, default: "" },
    prependIcon: { type: String, default: "mdi-clock-outline" },
    outlined: { type: Boolean, default: false },
  },
  computed: {
    time_range() {
      const from = this.from || "00:00";
      const to = this.to || "24:00";

      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds(from),
        get_HHmm_time_as_seconds(to) + this.interval,
        this.interval
      );
      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    _time: {
      get() {
        return this.time;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
  },
};
</script>
