var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"New category name","clearable":""},model:{value:(_vm.new_category_name),callback:function ($$v) {_vm.new_category_name=$$v},expression:"new_category_name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","disabled":!_vm.new_category_name || !_vm.can_add_new_category},on:{"click":function($event){return _vm.add_new_category()}}},[_vm._v("Add new category")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.has_categories ? "All Categories" : "Sorry no categories, try to add some.")+" ")])]),_vm._l((_vm.all_categories),function(name,cat_id){return _c('v-col',{key:cat_id,staticClass:"category-col py-0",attrs:{"cols":"6","col":"2"}},[_c('p',{staticClass:"pa-1 mb-0 rounded d-inline-block",class:{
          'primary lighten-1':
            _vm.new_category_name &&
            cat_id.includes(_vm.format_string_to_uid(_vm.new_category_name)),
        }},[_vm._v(" "+_vm._s(name)+" ("+_vm._s(_vm.shop_products_categories[cat_id] || "0")+" product"+_vm._s(_vm.shop_products_categories[cat_id] > 1 ? "s" : "")+") ")]),(
          !_vm.shop_products_categories[cat_id] ||
          _vm.shop_products_categories[cat_id] == 0
        )?_c('v-btn',{staticClass:"delete-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.delete_category(cat_id)}}},[_c('v-icon',{attrs:{"color":"accent","small":""},domProps:{"textContent":_vm._s('$delete')}})],1):_vm._e()],1)})],2),_c('v-snackbar',{attrs:{"timeout":1500,"color":"yellow","top":"","right":"","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-icon',{attrs:{"color":"black"},on:{"click":function($event){_vm.show_snackbar = false}}},[_vm._v("mdi-close-circle-outline")])]},proxy:true}]),model:{value:(_vm.show_snackbar),callback:function ($$v) {_vm.show_snackbar=$$v},expression:"show_snackbar"}},[_c('b',{staticClass:"black--text"},[_vm._v("Changes were saved successfully")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }